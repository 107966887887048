import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import useDebounce from 'utils/hooks/useDebounce'
import { isEmpty } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { searchBpsRoutine } from 'features/bpList/ducks/actions'
import { getSearchResults } from 'features/bpList/ducks/selectors'
import SearchListItem from 'features/SearchInput/components/SearchListItem'
import { getLastSearchedBps } from 'features/dashboard/ducks/selectors'
import { markBpAsSearchedRoutine } from 'features/bpDetails/ducks/actions'
import { fetchLastSearchedBpsRoutine } from 'features/dashboard/ducks/actions'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useNavigate } from "react-router-dom";

export const SearchInput = ({
  disabled = false,
  icon = SearchIcon,
  placeholder = 'Search my Building Passports',
  ...props
}) => {
  const [value, setValue] = useState('')
  const [boxOpen, setBoxOpen] = useState(false)
  const dispatch = useDispatch()
  const debouncedValue = useDebounce(value, 500)
  const lastSearched = useSelector(getLastSearchedBps)
  const userBps = useSelector(getSearchResults)
  const [bps, setBps] = useState([])
  const hasBps = isNotNilOrEmpty(bps)
  const searchIconOverrides = { fontSize: 25 }
  const handleClearValue = () => setValue('')
  const handleInputChange = e => setValue(e.target.value)
  const isSearching = debouncedValue.length >= 3
  const navigate = useNavigate()

  const handleBoxOpen = () => setBoxOpen(true)
  const handleBoxClose = () => setTimeout(() => setBoxOpen(false), 200)

  const handleClickItem = bp => () => {
    setValue('')
    dispatch(markBpAsSearchedRoutine({ bpId: bp.id }))
    window.location
    setTimeout(() => {
      navigate(`/bp-list/${bp.id}`, { replace: true })
      window.location.reload()
    }, 0)
    handleBoxClose()
  }

  useEffect(() => {
    dispatch(fetchLastSearchedBpsRoutine())
  }, [])

  useEffect(() => {
    isSearching
      ? setBps(userBps)
      : setBps(lastSearched)
  }, [lastSearched, userBps, isSearching])

  useEffect(() => {
    if (isSearching) {
      dispatch(searchBpsRoutine(debouncedValue))
    }
  }, [debouncedValue])

  return (
    <SearchInputContainer disabled={disabled}>
      <InputWrapper onClick={handleBoxOpen}>
        <SearchInputIcon>
          <StyledInputIcon style={searchIconOverrides}/>
        </SearchInputIcon>
        <StyledInput
          {...props}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={handleInputChange}
          onBlur={handleBoxClose}
        />
        {!isEmpty(value) && <StyledClearIcon onClick={handleClearValue}/>}
      </InputWrapper>
      {
        boxOpen && (
          <SearchBox>
            <BoxTitle>
              {
                isSearching
                  ? 'Results'
                  : 'Last searched'
              }
            </BoxTitle>
            {hasBps ? bps.map(bp =>
              <SearchListItem
                disabled={bp.status === 'dormant'}
                onClick={handleClickItem(bp)}
                bp={bp}
                key={`${bp.id}-${Math.random() * 99}`}
              />
            ) : isSearching
              ? <NoResultsInfo>No results matching search criteria</NoResultsInfo>
              : <NoResultsInfo>No previous search history to display</NoResultsInfo>}
          </SearchBox>
        )
      }
    </SearchInputContainer>
  )
}

export default SearchInput

const StyledInput = styled.input`
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  line-height: 16px;
  font-size: 16px;
  flex-grow: 2;
  padding-right: 20px;
  height: 100%;
`

const SearchInputContainer = styled.div`
  border-radius: 4px;
  height: 100%;
  box-sizing: border-box;
  opacity: ${props => (props.disabled ? '.5' : '1')};
  width: 100%;
  display: flex;
  position: relative;
`

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 30px;
`

const SearchInputIcon = styled.div`
  position: relative;
  top: 2px;
  margin-right: 15px;
  color: rgba(152, 152, 152, 0.61);
`
const StyledInputIcon = styled(SearchIcon)`
  font-size: 25px !important;
`

const StyledClearIcon = styled(ClearIcon)`
  cursor: pointer;
  position: absolute;
  right: 65px;
  padding: 10px;
  font-size: 16px;
  color: rgba(152, 152, 152, 0.61);
`

const SearchBox = styled.div`
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  padding: 24px 32px;
  position: absolute;
  top: ${({ theme }) => theme.dimensions.topBarHeight};
  z-index: 3;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
  background-color: ${({ theme }) => theme.colors.navigation};
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  border-top: 2px solid ${({ theme }) => theme.colors.secondary};
`

const BoxTitle = styled.div`
  padding-bottom: 10px;
  width: 200px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
  color: ${({ theme }) => theme.colors.haiti};
  opacity: 60%;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 7px;
`

const NoResultsInfo = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.haiti};
`

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PageHeader from 'components/atoms/PageHeader'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUserRole } from 'features/auth/ducks/selectors'
import BpList from 'features/bpList/BpList'
import UnitsList from 'features/bpList/UnitsList'
import { ReactComponent as MyBpsIcon } from 'assets/images/Menu2.svg'
import { ReactComponent as LockIcon } from 'assets/images/lock-unlocked.svg'
import {
  clearSelectedBpRoutine,
  getBpsCountRoutine,
  getUnitsCountRoutine
} from 'features/bpList/ducks/actions'
import {
  selectBpsCount,
  selectUnitsCount
} from 'features/bpList/ducks/selectors'

const MainList = () => {
  const currentUserRole = useSelector(getCurrentUserRole)
  const [activeTab, setActiveTab] = useState('bp')
  const dispatch = useDispatch()
  const bpsCount = useSelector(selectBpsCount)
  const unitsCount = useSelector(selectUnitsCount)

  const handleTabChange = tabName => () => setActiveTab(tabName)

  useEffect(() => {
    dispatch(getBpsCountRoutine())
    dispatch(getUnitsCountRoutine())
    dispatch(clearSelectedBpRoutine())
  }, [])

  useEffect(() => {
    if (bpsCount === 0 && unitsCount > 0) {
      setActiveTab('unit')
    } else {
      setActiveTab('bp')
    }
  }, [bpsCount, unitsCount])

  return (
    <Wrapper>
      <PageHeader>
        {currentUserRole === 'emergency_service'
          ? 'Building Passports'
          : 'My Building Passports'}
      </PageHeader>
      <TabsWrapper>
        <Tab active={activeTab === 'bp'} onClick={handleTabChange('bp')}>
          <MyBpsIcon /> Building Passports ({bpsCount})
        </Tab>
        <Tab active={activeTab === 'unit'} onClick={handleTabChange('unit')}>
          <LockIcon /> Units ({unitsCount})
        </Tab>
      </TabsWrapper>
      <ContentWrapper>
        {activeTab === 'bp' && <BpList />}
        {activeTab === 'unit' && <UnitsList />}
      </ContentWrapper>
    </Wrapper>
  )
}

export default MainList

const Wrapper = styled.div``

const TabsWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
`

const Tab = styled.div`
  height: 40px;
  width: fit-content;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid
    ${({ theme, active }) => (active ? theme.colors.secondary : 'transparent')};
  font-size: 14px;

  svg {
    width: 18px;
    margin-right: 10px;
  }
`

const ContentWrapper = styled.div`
  padding: 20px 0;
`

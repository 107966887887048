import React from 'react'
import ReactDOM from 'react-dom/client'
import GlobalStyles from 'theme/globalStyles'
import theme from 'theme/theme'
import { ThemeProvider } from 'styled-components'
import * as serviceWorker from './serviceWorker'
import ReduxProvider from 'providers/ReduxProvider'
import App from './App'
import './i18n'
import 'assets/fonts/fonts.css'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { BrowserRouter as Router } from 'react-router-dom'

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)
root.render(
  /* TODO: Check the Strict mode for errors*/
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <ReduxProvider>
          <Router>
            <App />
          </Router>
        </ReduxProvider>
      </ThemeProvider>
    </DndProvider>
  </React.StrictMode>
)

serviceWorker.unregister()

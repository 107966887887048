import React, { useEffect, useMemo } from 'react'
import { useDrop } from 'react-dnd'
import { changeNewTemplateValueRoutine } from 'features/analytics/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectAddTemplateValues } from 'features/analytics/ducks/selectors'
import styled from 'styled-components'
import { TEMPLATE_ITEMS_NAMES } from 'utils/constants'
import ClearIcon from '@material-ui/icons/Clear'

const DataDropContainer = ({ isDefaultTemplate, isEdit, dataValues, setDataValues }) => {
  const dispatch = useDispatch()
  const selectedValues = useSelector(selectAddTemplateValues)

  const [{ canDrop, isOver }, dropRef] = useDrop({
    accept: 'template-data',
    drop: (item, monitor) => {
      if (!monitor.didDrop()) {
        // Only toggle if the value isn't already set in edit mode
        if (isEdit) {
          if (!dataValues[item.value]) {
            toggleValue(item.value)
          }
        } else {
          toggleValue(item.value)
        }
      }
      return { droppedOn: 'DataDropContainer' }
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver()
    })
  })


  const valuesToDisplay = useMemo(() => {
    let result = []
    const values = isEdit ? dataValues : selectedValues
    for (const key in values) {
      if (values[key]) {
        result.push(key)
      }
    }
    return result
  }, [selectedValues, dataValues])

  const toggleValue = value => {
    if (isEdit) {
      setDataValues(prev => ({
        ...prev,
        [value]: !dataValues[value]
      }))
    } else {
      dispatch(changeNewTemplateValueRoutine({
        name: value,
        value: !selectedValues[value]
      }))
    }
  }

  return (
    <Content>
      <SectionTitle>data</SectionTitle>
      {
        valuesToDisplay.map(item => {
            return (
              <SingleItem key={item}>
                <div>
                  <span>•</span>{TEMPLATE_ITEMS_NAMES[item]}
                </div>
                <CrossIcon
                  disabled={isDefaultTemplate}
                  onClick={
                    isDefaultTemplate
                      ? () => {}
                      : () => toggleValue(item)
                  }
                />
              </SingleItem>
            )
          })
      }
      {!isDefaultTemplate && <DropContainer ref={dropRef} isHighlighted={canDrop && isOver} />}
    </Content>
  )
}

export default DataDropContainer

const DropContainer = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px dashed ${({ theme }) => theme.colors.grey[600]};
  margin-top: 20px;
  transition: .3s all;
  background-color: ${({ isHighlighted }) => isHighlighted ? '#eaf9fa' : 'transparent'};
`

const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  padding: 20px;
`

const SingleItem = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;

  span {
    display: inline-block;
    color: ${({ theme }) => theme.colors.secondary};
    margin-right: 10px;
  }
`

const SectionTitle = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 10px;
`

const CrossIcon = styled(ClearIcon)`
  font-size: 14px !important;
  cursor: pointer;
  color: ${({ disabled, theme }) =>  disabled ? theme.colors.grey[600] : theme.colors.primary};
`

import React, { useEffect, useMemo, useState } from 'react'
import useDebounce from 'utils/hooks/useDebounce'
import Input from 'components/atoms/Input'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import { isNotNilOrEmpty } from 'utils/ramda'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { useSelector } from 'react-redux'
import { getFileTypes } from 'ducks/files/selectors'
import { pathOr } from 'ramda'

const FileTypesSearch = ({ category, onFileTypeSelect, value, label, hasError, placeholder }) => {
  const fileTypes = useSelector(getFileTypes)
  const [searchQuery, setSearchQuery] = useState('')
  const [open, setOpen] = useState(false)
  const debouncedValue = useDebounce(searchQuery, 500)

  const handleSearch = (_, query) => {
    setSearchQuery(query)
  }

  const handleValueSet = () => {
    if (isNotNilOrEmpty(value) && fileTypes) {
      const type = fileTypes.find(t => t.id === value)
      setSearchQuery(type?.name || '')
    } else {
      setSearchQuery('')
    }
  }

  useEffect(() => {
    handleValueSet()
  }, [value])

  useEffect(() => {
    if (!open) {
      handleValueSet()
    }
  }, [open])

  const typesOptions = useMemo(() => {
    if (fileTypes) {
      if (isNotNilOrEmpty(searchQuery)) {
        return fileTypes
          .map(type => ({
            label: type.name,
            value: type.id,
            description: type.description,
            categoryName: pathOr('', ['fileCategory', 'name'], type),
            categoryId: pathOr('', ['fileCategory', 'id'], type),
          }))
      } else {
        if (isNotNilOrEmpty(category)) {
          return fileTypes
            .filter(v => v.fileCategoryId === category)
            .map(type => ({
              label: type.name,
              value: type.id,
              description: type.description,
              categoryName: pathOr('', ['fileCategory', 'name'], type),
              categoryId: pathOr('', ['fileCategory', 'id'], type),
            }))
        } else {
          return fileTypes
            .map(type => ({
              label: type.name,
              value: type.id,
              description: type.description,
              categoryName: pathOr('', ['fileCategory', 'name'], type),
              categoryId: pathOr('', ['fileCategory', 'id'], type),
            }))
        }
      }
    } else {
      return []
    }
  }, [category, searchQuery])

  const foundTypes = useMemo(() => {
    const query = debouncedValue.replace(/\s/gi, '').toLowerCase()
    return typesOptions && typesOptions.filter(type => {
      const searchPlaces = [type.label, type.description, type.categoryName].map(place => {
        return place && place.replace(/\s/gi, '').toLowerCase()
      })

      return searchPlaces.some(place => {
        return place && place.includes(query)
      })
    })
  }, [debouncedValue, typesOptions])

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <InputWrapper>
      <Input
        onChange={handleSearch}
        value={searchQuery}
        name='searchQuery'
        label={label}
        onFocus={() => setOpen(true)}
        nomarginbottom
        hasError={hasError}
        placeholder={placeholder}
      />
      {
        open && (
          <Results>
            <TableHeader>
              <div>Category</div>
              <div>File type</div>
            </TableHeader>
            {
              foundTypes && foundTypes.map(type => {
                return isNotNilOrEmpty(type.description)
                  ? (
                    <Tooltip title={type.description} key={type.value}>
                      <TableRow onClick={() => {
                        onFileTypeSelect(type.categoryId, type.value)
                        setOpen(false)
                        setSearchQuery('')
                      }}>
                        <div>{type.categoryName}</div>
                        <div>{type.label}</div>
                      </TableRow>
                    </Tooltip>
                  )
                  : (
                    <TableRow key={type.value} onClick={() => {
                      onFileTypeSelect(type.categoryId, type.value)
                      setOpen(false)
                      setSearchQuery('')
                    }}>
                      <div>{type.categoryName}</div>
                      <div>{type.label}</div>
                    </TableRow>
                  )
              })
            }
          </Results>
        )
      }
      </InputWrapper>
    </ClickAwayListener>
  )
}

export default FileTypesSearch

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`

const Results = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 70px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
  border-color: ${({ theme }) => theme.colors.grey[500]};
  background-color: ${({ theme }) => theme.colors.white};
  max-height: 250px;
  overflow-y: auto;
  z-index: 100;
  font-size: 12px;
  padding: 10px 0;
  width: 300px;
`

const TableHeader = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.grey[700]};

  div {
    width: 50%;
    padding: 5px;
    box-sizing: border-box;
  }
`

const TableRow = styled.div`
  display: flex;
  transition: all .3s;

  div {
    width: 50%;
    padding: 5px;
    box-sizing: border-box;
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
  }
`

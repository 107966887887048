import React from 'react'
import styled from 'styled-components'
import { PATHS, redirect } from 'utils/paths'
import { Navigate } from "react-router-dom";

const NotFound = () => {
  return (
    <Wrapper>
      <Content>
        <HeaderWrapper>
          <Header>
            404
          </Header>
          <Subtext>
            We couldn’t find
            this page.
          </Subtext>
        </HeaderWrapper>
        <BackButton onClick={() => redirect(PATHS.home)}>
          Back to dashboard
        </BackButton>
      </Content>
    </Wrapper>
  )
}

export default NotFound

export const RedirectToNotFound = () => {
  return <Navigate to={PATHS.notFound}/>
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  padding-top: 50px;
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Content = styled.div`
  width: 550px;
  color: ${({ theme }) => theme.colors.haiti};
  text-align: center;
`

const Header = styled.div`
  font-size: 126px;
`

const Subtext = styled.div`
  font-size: 45px;
  line-height: 47px;
  font-weight: bold;
  color: #b8c0df;
  margin-left: 30px;
  text-align: left;
`

const BackButton = styled.button`
  border: 1px solid transparent;
  box-shadow: 0 0 20px 0 rgba(41, 152, 163, 0.31);
  padding: 10px 30px;
  font-size: 16px;
  margin-top: 20px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.haiti};
  color: ${({ theme }) => theme.colors.white};
  transition: all .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.haiti};
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.haiti};
  }
`

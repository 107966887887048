import React from 'react'
import styled from 'styled-components'
import LogoShort from 'assets/images/bp-logo-orange.png'
import LogoFull from 'assets/images/bp-logo.png'
import { PATHS, PRIVATE_PATHS, redirect } from 'utils/paths'

const Logo = ({ isloggedin }) => {
  return isloggedin
    ? (
      <ShortLogoWrapper onClick={() => redirect(PRIVATE_PATHS.dashboard)}>
        <img
          src={LogoShort}
          alt='company logo'
        />
      </ShortLogoWrapper>
    ) : (
      <LogoWrapper onClick={() => redirect(PATHS.home)}>
        <img
          src={LogoFull}
          alt='company logo'
        />
      </LogoWrapper>
    )
}

export default Logo

const ShortLogoWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${({ theme }) => theme.dimensions.navWidth};
  height: ${({ theme }) => theme.dimensions.topBarHeight};
  z-index: 999;

  img {
    width: auto;
    height: 34px;
    cursor: pointer;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 250px;

  img {
    max-width: 250px;
    max-height: 100%;
    cursor: pointer;
  }
`

import React, { useEffect, useState } from 'react'
import Panel from 'features/createBp/components/atoms/Panel'
import { useTranslation } from 'react-i18next'
import {
  validateBuildingInformationField,
  validateBuildingInformationWithoutUprnField,
  validateBuildingInformationWithoutUprnValues
} from 'features/createBp/ducks/schema'
import Input from 'components/atoms/Input'
import HelpIcon from '@material-ui/icons/Help'
import styled from 'styled-components'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Tooltip from '@material-ui/core/Tooltip'
import NextButton from 'features/createBp/components/atoms/NextButton'
import { propOr } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { getCreatedBpId } from 'features/createBp/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { updateBpRoutine } from 'features/createBp/ducks/actions'
import InputDescription from 'features/createBp/components/atoms/InputDescription'

const BuildingInformation = ({
  completed,
  open,
  setActiveStep,
  setBp,
  setCompleted,
  bp
}) => {
  const { t } = useTranslation()
  const [sectionValues, setSectionValues] = useState({
    name: propOr('', 'name', bp),
    uprn: propOr('', 'uprn', bp),
    postalCode: propOr('', 'postalCode', bp),
    street: propOr('', 'street', bp),
    city: propOr('', 'city', bp),
    expectedUprnAssignmentDate: propOr(null, 'expectedUprnAssignmentDate', bp),
    groundFloor: true
  })
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [valid, setIsValid] = useState(false)
  const dispatch = useDispatch()
  const bpId = useSelector(getCreatedBpId)

  const openTooltip = () => setTooltipOpen(true)
  const closeTooltip = () => setTooltipOpen(false)

  const handleOnChange = (name, value) =>
    setSectionValues({ ...sectionValues, [name]: value })

  const setValues = (name, values) => {
    handleOnChange(name, values)
  }

  useEffect(() => {
    setSectionValues({
      name: propOr('', 'name', bp),
      uprn: propOr('', 'uprn', bp),
      postalCode: propOr('', 'postalCode', bp),
      street: propOr('', 'street', bp),
      city: propOr('', 'city', bp),
      expectedUprnAssignmentDate: propOr(null, 'expectedUprnAssignmentDate', bp),
      groundFloor: propOr(true, 'groundFloor', bp)
    })
  }, [bp])

  const setNextSection = () => {
    setActiveStep('building-details')
    setBp(prev => ({
      ...prev,
      ...sectionValues,
      uprn: sectionValues.uprn
    }))
    setCompleted('buildingInfo')

    if (isNotNilOrEmpty(bpId)) {
      dispatch(updateBpRoutine({
        ...sectionValues,
        id: bpId,
        uprn: sectionValues.uprn,
        isEdit: true,
        isWizard: true
      }))
    }
  }

  useEffect(() => {
    validateBuildingInformationWithoutUprnValues(sectionValues, setIsValid)
  }, [sectionValues])

  return (
    <Panel
      open={open}
      title='Building information'
      completed={completed}
      step='building-info'
      setActiveStep={setActiveStep}
    >
      <Input
        required
        label={t('createBp.labels.name')}
        placeholder='eg. 17 Basil Street, London'
        value={sectionValues.name}
        name='name'
        onChange={setValues}
        validate={validateBuildingInformationField(sectionValues)}
      />
      <Input
        required
        nomarginbottom
        label={t('createBp.labels.uprn')}
        value={sectionValues.uprn}
        name='uprn'
        onChange={setValues}
        validate={validateBuildingInformationWithoutUprnField(setSectionValues)}
      />
      <ClickAwayListener onClickAway={closeTooltip}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={closeTooltip}
          open={tooltipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title='UPRN stands for Unique Property Reference Number and was created by the Ordnance Survey (OS).
           It consists of numbers of up to 12 digits in length. Local governments in the UK have allocated
           a unique number for each land or property.'
        >
          <UprnHint>
            <QuestionIcon />
            <UprnHintText onClick={openTooltip}>What is UPRN?</UprnHintText>
          </UprnHint>
        </Tooltip>
      </ClickAwayListener>
     <UprnDescription>
        <InputDescription>
          If you don’t know your UPRN, you can find it&nbsp;
          <a
            href='https://www.findmyaddress.co.uk/search'
            target='_blank'
            rel='noreferrer'
          >
            here
          </a>
        </InputDescription>
      </UprnDescription>
      <Input
        required
        label={t('createBp.labels.postCode')}
        value={sectionValues.postalCode}
        name='postalCode'
        onChange={setValues}
        validate={validateBuildingInformationField(sectionValues)}
      />
      <Input
        required
        label={t('createBp.labels.address')}
        value={sectionValues.street}
        name='street'
        onChange={setValues}
        validate={validateBuildingInformationField(sectionValues)}
      />
      <Input
        required
        label={t('createBp.labels.city')}
        value={sectionValues.city}
        name='city'
        onChange={setValues}
        validate={validateBuildingInformationField(sectionValues)}
      />
      <NextButton
        onClick={setNextSection}
        disabled={!valid}
      >
        {completed ? 'Save' : t('createBp.labels.next')}
      </NextButton>
    </Panel>
  )
}

export default BuildingInformation

const QuestionIcon = styled(HelpIcon)`
  color: #a7b5e8;
  margin-right: 5px;
  font-size: 16px !important;
`

const UprnHint = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0 15px;
`

const UprnHintText = styled.div`
  cursor: pointer;
  font-size: 12px;
`

const UprnDescription = styled.div`
  margin-bottom: 20px;

  a {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
  }
`

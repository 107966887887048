import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import SearchIcon from '@material-ui/icons/Search'
import Checkbox from 'components/atoms/Checkbox'
import { isNil } from 'ramda'
import { useDispatch } from 'react-redux'
import useDebounce from 'utils/hooks/useDebounce'

const SelectedOption = ({ option, setItems }) => {
  const handleRemoveItem = e => {
    e.stopPropagation()
    setItems(prev => prev.filter(e => e.value !== option.value))
  }

  return (
    <OptionWrapper>
      {option.label}
      <CloseIcon onClick={handleRemoveItem}>✘</CloseIcon>
    </OptionWrapper>
  )
}

const Option = ({ item, setItems, isactive }) => {
  const [checked, setChecked] = useState(isactive === "true")

  useEffect(() => {
    setChecked(isactive === "true")
  }, [isactive === "true"])

  const handleChange = () => {
    setChecked(prevChecked => {
      setItems(prevItems => {
        const filtered = prevItems.filter(e => e.value !== item.value)
        return prevChecked
          ? filtered
          : [ ...filtered, item ]
      })
      return !prevChecked
    })
  }

  return (
    <DropdownOptionWrapper>
      <Checkbox onChange={handleChange} label={item.label} isChecked={checked} />
    </DropdownOptionWrapper>
  )
}

const MultiSelect = ({ name, options, handleChange, filters, searchRoutine }) => {
  const [selectOptions, setSelectOptions] = useState(options)
  const [expanded, setExpanded] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedQuery = useDebounce(searchQuery, 500)
  const dispatch = useDispatch()

  const expandOptions = () => setExpanded(prev => !prev)

  useEffect(() => {
    typeof handleChange === 'function' && handleChange(name, selectedItems)
  }, [selectedItems])

  const setQuery = e => {
    setSearchQuery(e.target.value)
  }

  useEffect(() => {
    isNil(filters) && setSelectedItems([])
  }, [filters])

  useEffect(() => {
    if (typeof searchRoutine === 'function' && debouncedQuery.length > 0) {
      dispatch(searchRoutine(searchQuery))
    } else {
      searchQuery.length >= 1
        ? setSelectOptions(options.filter(e =>
          e.label.toLowerCase().includes(searchQuery.toLowerCase())))
        : setSelectOptions(options)
    }
  }, [debouncedQuery])

  useEffect(() => {
    if (typeof searchRoutine === 'function') {
      setSelectOptions(options)
    }
  }, [options])

  return (
    <>
      <MultiSelectWrapper onClick={expandOptions}>
        <SelectContent>
          {
            selectedItems.length > 0
              ? selectedItems.map((item, index) => {
                return <SelectedOption
                  key={`${item.label}-${index}`}
                  setItems={setSelectedItems}
                  option={item}
                />
              })
              : <Placeholder>Select</Placeholder>
          }
        </SelectContent>
        <ExpandMore>
          <StyledExpandIcon expanded={expanded} />
        </ExpandMore>
      </MultiSelectWrapper>
      <StyledCollapse in={expanded}>
        <SearchInputWrapper>
          <StyledSearchIcon />
          <StyledInput onChange={setQuery} placeholder='Enter the details' />
        </SearchInputWrapper>
        <Explanation>Enter at least 3 characters to start searching</Explanation>
        <CollapseContent>
          {
            selectOptions && selectOptions.length > 0
              ? selectOptions.map((option, index) => {
                return <Option
                  key={`${option.value}-${index}`}
                  isactive={(selectedItems.some(e => e.value === option.value)).toString()}
                  item={option}
                  setItems={setSelectedItems}
                />
              })
              : <NoResult>No results</NoResult>
          }
        </CollapseContent>
      </StyledCollapse>
    </>
  )
}

export default MultiSelect

const MultiSelectWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  width: 100%;
  min-height: 38px;
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`

const OptionWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 5px 5px 10px;
  font-size: 12px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.secondary};
  position: relative;
  margin: 2px;
`

const CloseIcon = styled.div`
  color: ${({ theme }) => theme.colors.white};
  padding: 3px;
  margin-left: 5px;
`

const SelectContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 6px;
`

const ExpandMore = styled.div`
  box-sizing: border-box;
  min-width: 38px;
  max-width: 38px;
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: center;
  border-left: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  position: relative;
  top: 0;
  bottom: 0;
  padding: 10px 0;
  cursor: pointer;
`

const Placeholder = styled.div`
  color: ${({ theme }) => theme.colors.haiti};
  display: flex;
  flex: 1;
  align-items: center;
  padding: 5px 5px 0;
`

const StyledCollapse = styled(Collapse)`
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  background-color: ${({ theme }) => theme.colors.white};
  border-top: none;
`

const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`

const StyledSearchIcon = styled(SearchIcon)`
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.mediumGrey};
`

const StyledInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.haiti};
    opacity: .5;
  }
`

const CollapseContent = styled.div`
  padding: 10px;
  max-height: 150px;
  overflow-y: auto;
`

const DropdownOptionWrapper = styled.div`
  display: flex;
  padding: 5px 0;
`

const Explanation = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.secondary};
  padding: 10px 10px 0;
`

const NoResult = styled.div`
  color: ${({ theme }) => theme.colors.haiti};
  opacity: .5;
`

const StyledExpandIcon = styled(ExpandMoreIcon)`
  transform: ${({ expanded }) => expanded ? 'rotate(-180deg)' : 'rotate(0)'};
`

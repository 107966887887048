import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as FloorPlansIcon } from 'assets/images/folders/flor_plans.svg'
import { ReactComponent as FrsIcon } from 'assets/images/folders/frs.svg'
import { ReactComponent as OtherDocumentsIcon } from 'assets/images/folders/documents.svg'
import { ReactComponent as ModelIcon } from 'assets/images/folders/3d_model.svg'
import { ReactComponent as NotesIcon } from 'assets/images/folders/notes.svg'
import { ReactComponent as AccessControlIcon } from 'assets/images/folders/access_control.svg'
// import { ReactComponent as AnalyticsIcon } from 'assets/images/folders/analysis.svg'
import { ReactComponent as UnitsIcon } from 'assets/images/folders/units.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { clearSelectedUnitRoutine } from 'ducks/units/actions'
import { selectHasAccessToBp } from 'features/bpDetails/ducks/selectors'
import { useParams } from 'react-router'
import { redirect } from 'utils/paths'

const Folder = ({ label, valueKey, onClick, icon: Icon, isactive }) => {
  const toggle = () => onClick(valueKey)
  return (
    <FolderWrapper>
      <FolderHeader
        onClick={toggle}
        isactive={(isactive).toString()}
      >
        <LabelWrapper>
          <IconWrapper isactive={(isactive).toString()}>
            <Icon />
          </IconWrapper>
          <FolderLabel>{label}</FolderLabel>
        </LabelWrapper>
      </FolderHeader>
    </FolderWrapper>
  )
}

const Folders = ({ redirectPath }) => {
  const { id, folder } = useParams()
  const currentUser = useSelector(getCurrentUser)
  const isFrs = currentUser.type === 'emergency_service'
  // const selectedBp = useSelector(getSelectedBp)
  // const isDraft = selectedBp.status === 'draft'
  const hasFullAccess = useSelector(selectHasAccessToBp)
  const dispatch = useDispatch()

  const handleFolderClick = key => {
    redirect(`${redirectPath}/${id}/${key}`)
  }

  return (
    <div>
      <Header>Menu</Header>
      <FoldersWrapper>
        {
          hasFullAccess && (
            <Folder
              valueKey='floorplans'
              label='Plans'
              onClick={handleFolderClick}
              isactive={(folder === 'floorplans').toString()}
              icon={FloorPlansIcon}
            />
          )
        }
        {
          isFrs && (
            <Folder
              valueKey='frs'
              label='FRS Documents'
              onClick={handleFolderClick}
              isactive={(folder === 'frs').toString()}
              icon={FrsIcon}
            />
          )
        }
        {
          hasFullAccess && (
            <>
              <Folder
                valueKey='other'
                label='Documents'
                onClick={handleFolderClick}
                isactive={(folder === 'other').toString()}
                icon={OtherDocumentsIcon}
              />
              <Folder
                valueKey='model'
                label='3D model'
                onClick={handleFolderClick}
                isactive={(folder === 'model').toString()}
                icon={ModelIcon}
              />
              <Folder
                valueKey='notes'
                label='Notes'
                onClick={handleFolderClick}
                isactive={(folder === 'notes').toString()}
                icon={NotesIcon}
              />
              <Folder
                valueKey='accessControl'
                label='Access Control'
                onClick={handleFolderClick}
                isactive={(folder === 'accessControl').toString()}
                icon={AccessControlIcon}
              />
            </>
          )
        }

        {/*{*/}
        {/*  !isFrs && !isDraft && hasFullAccess && (*/}
        {/*    <Folder*/}
        {/*      valueKey='analytics'*/}
        {/*      label='Analytics'*/}
        {/*      onClick={handleFolderClick}*/}
        {/*      isactive={(folder === 'analytics').toString()}*/}
        {/*      icon={AnalyticsIcon}*/}
        {/*    />*/}
        {/*  )*/}
        {/*}*/}
        <Folder
          valueKey='units'
          label='Units'
          onClick={folderName => {
            handleFolderClick(folderName)
            dispatch(clearSelectedUnitRoutine())
          }}
          isactive={(folder === 'units').toString()}
          icon={UnitsIcon}
        />
        {
          !hasFullAccess && (
            <Folder
              valueKey='noticeBoard'
              label='Notice Board'
              onClick={handleFolderClick}
              isactive={(folder === 'noticeBoard').toString()}
              icon={NotesIcon}
            />
          )
        }
      </FoldersWrapper>
    </div>
  )
}

export default Folders

const FoldersWrapper = styled.div`
  width: 100%;
`

const Header = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: .3px;
  color: ${({ theme }) => theme.colors.haiti};
`

const FolderHeader = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  margin-top: 13px;
  border-radius: 5px;
  Height: 53px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 20px;
  cursor: pointer;
  transition: all .3s;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lighterGrey};
  }

  ${({ isactive }) => isactive === "true" && css`
    background-color: ${({ theme }) => theme.colors.haiti};
    border-color: ${({ theme }) => theme.colors.haiti};
    color: ${({ theme }) => theme.colors.white};

    &:hover {
      background-color: ${({ theme }) => theme.colors.haiti};
    }
  `}
`

const FolderLabel = styled.div`
  padding-left: 10px;
  font-size: 14px;
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`

const IconWrapper = styled.div`
  width: 35px;
  & * {
    fill: ${({ theme, isactive }) => isactive === "true" ? theme.colors.white : '#c9d4fb'} !important;
  }
`

const FolderWrapper = styled.div`
  width: 200px;
`

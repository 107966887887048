import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import { dissoc, isEmpty, omit, pathOr } from 'ramda'
import { useSelector } from 'react-redux'
import { getBuildingTypes } from 'features/bpList/ducks/selectors'
import { dateFilterOptions } from 'utils/date'
import MultiSelect from 'features/filters/components/MultiSelect'
import RadioDropdown from 'features/filters/components/RadioDropdown'
import ChoiceFilter from 'features/filters/components/ChoiceFilter'
import CloseIcon from '@material-ui/icons/Close'
import { SmallerScreenContent } from 'utils/responsive'
import { getBpConnectedUsers } from 'features/bpDetails/ducks/selectors'
import { getBpConnectedUsersRoutine } from 'features/bpDetails/ducks/actions'

const Filter = ({ label, children, hasSelectedFilters, clearFilters }) => {
  return (
    <FilterWrapper>
      <FilterHeader>
        <HeaderLabel>{label}</HeaderLabel>
        {hasSelectedFilters && <Clear onClick={clearFilters}>Clear</Clear>}
      </FilterHeader>
      {children}
    </FilterWrapper>
  )
}

const UnitFilters = ({
   withCloseIcon,
   getFilters,
   parentFilters = {},
   closeDrawer,
   defaultFilters
}) => {
  const [filters, setFilters] = useState(parentFilters)
  const buildingTypes = useSelector(getBuildingTypes)
  const connectedUsers = useSelector(getBpConnectedUsers)

  const clearSelectedFilter = type => () => {
    if (typeof type === 'object') {
      setFilters(omit(type, filters))
    } else {
      setFilters(dissoc(type, filters))
    }
  }

  useEffect(() => {
    if ((isEmpty(parentFilters) || parentFilters === defaultFilters) && !isEmpty(filters)) {
      setFilters(defaultFilters)
    }
  }, [parentFilters])

  const handleChangeFilters = (name, selectedItems) => {
    setFilters(prev => ({
      ...prev,
      [name]: selectedItems
    }))
  }

  useEffect(() => {
    getFilters(filters)
  }, [filters])

  const buildingTypeOptions = useMemo(() => {
    let result = []
    for (const key in buildingTypes) {
      result = [
        ...result,
        {
          label: pathOr('', [key, 'label'], buildingTypes),
          value: pathOr('', [key, 'key'], buildingTypes)
        }
      ]
    }
    return result
  }, [buildingTypes])

  const getUserOptions = useMemo(() => {
    return isNotNil(connectedUsers)
      ? connectedUsers.map(user => ({
          label: `${user.firstName} ${user.lastName} ${user.company ? '(' + user.company + ')' : ''}`,
          value: user.id
        }))
      : []
  }, [connectedUsers])

  return (
    <div>
      <FiltersHeader>
        <Title>
          Filters
        </Title>
        <SmallerScreenContent>
          <StyledCloseIcon onClick={closeDrawer} />
        </SmallerScreenContent>
        {
          withCloseIcon && <StyledCloseIcon onClick={closeDrawer} />
        }
      </FiltersHeader>
      <Filter
        label='Building Owner'
        hasSelectedFilters={isNotNilOrEmpty(filters.owner)}
        clearFilters={clearSelectedFilter('owner')}
      >
        <MultiSelect
          name='owner'
          filters={filters.owner}
          handleChange={handleChangeFilters}
          options={getUserOptions}
          searchRoutine={getBpConnectedUsersRoutine}
        />
      </Filter>
      <Filter
        label='Responsible person'
        hasSelectedFilters={isNotNilOrEmpty(filters.responsible_person)}
        clearFilters={clearSelectedFilter('responsible_person')}
      >
        <MultiSelect
          name='responsible_person'
          filters={filters.responsible_person}
          handleChange={handleChangeFilters}
          options={getUserOptions}
          searchRoutine={getBpConnectedUsersRoutine}
        />
      </Filter>
      <Filter
        label='Created within'
        hasSelectedFilters={isNotNilOrEmpty(filters.created_at)}
        clearFilters={clearSelectedFilter('created_at')}
      >
        <RadioDropdown
          name='created_at'
          withDateInputs
          filters={filters.created_at}
          handleChange={handleChangeFilters}
          options={dateFilterOptions}
        />
      </Filter>
      <Filter
        label='Updated within'
        hasSelectedFilters={isNotNilOrEmpty(filters.updated_at)}
        clearFilters={clearSelectedFilter('updated_at')}
      >
        <RadioDropdown
          name='updated_at'
          filters={filters.updated_at}
          handleChange={handleChangeFilters}
          withDateInputs
          options={dateFilterOptions}
        />
      </Filter>
      {
        isNotNilOrEmpty(buildingTypeOptions) &&
          <Filter
            label='Unit type'
            hasSelectedFilters={isNotNilOrEmpty(filters.building_types)}
            clearFilters={clearSelectedFilter('building_types')}
          >
            <MultiSelect
              name='building_types'
              filters={filters.building_types}
              handleChange={handleChangeFilters}
              options={buildingTypeOptions}
            />
          </Filter>
      }
      <Filter
        label='Residents'
        hasSelectedFilters={
          isNotNilOrEmpty(filters.occupied) ||
          isNotNilOrEmpty(filters.disabilities) ||
          isNotNilOrEmpty(filters.elderly)
        }
        clearFilters={clearSelectedFilter([
          'occupied',
          'disabilities',
          'elderly'
        ])}
      >
        <ChoiceFilter
          name='occupied'
          filters={filters.occupied}
          label='Occupied'
          handleChange={handleChangeFilters}
        />
        <ChoiceFilter
          name='disabilities'
          filters={filters.disabilities}
          label='Disabilities'
          handleChange={handleChangeFilters}
        />
        <ChoiceFilter
          name='elderly'
          filters={filters.elderly}
          label='Elderly'
          handleChange={handleChangeFilters}
        />
      </Filter>
    </div>
  )
}

export default UnitFilters

const Title = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
`

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  margin-bottom: 10px;
`

const HeaderLabel = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  ${({ theme }) => theme.colors.haiti};
  opacity: .7;
`

const Clear = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
  cursor: pointer;
`

const FiltersHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledCloseIcon = styled(CloseIcon)`
  transition: all .3s;
  cursor: pointer;
  font-size: 30px !important;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

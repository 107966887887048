import React, { useMemo } from 'react'
import { Navigate, Route } from 'react-router-dom'
import { PATHS } from 'utils/paths'
import { isNotNilOrEmpty } from 'utils/ramda'
import GlobalLoader from './GlobalLoader'

const PrivateRoute = ({ allowedRoles, userRole = null, children }) => {
  const hasValidRole = useMemo(() => {
    return userRole && allowedRoles ? allowedRoles.includes(userRole) : false
  }, [userRole])

  if (isNotNilOrEmpty(userRole)) {
    return hasValidRole ? children : <Navigate to={PATHS.login} replace />
  } else {
    return <div />
  }
}

export default PrivateRoute

import React, { useEffect, useState } from 'react'
import MuiPagination from '@material-ui/lab/Pagination'
import { isNotNil } from 'utils/ramda'
import styled from 'styled-components'

export const Pagination = ({
  currentPage,
  onChange = () => {},
  resetTriggers = [],
  totalPages = 1
}) => {
  const [page, setPage] = useState(1)

  useEffect(() => {
    isNotNil(currentPage) && setPage(currentPage)
  }, [currentPage])

  useEffect(() => {
    setPage(1)
  }, resetTriggers)

  const handlePageChange = (e, page) => {
    setPage(page)
    onChange(page)
  }

  const shouldRender = totalPages > 1

  return shouldRender && (
    <PaginationWrapper>
      <MuiPagination
        shape='rounded'
        onChange={handlePageChange}
        page={page}
        count={totalPages}
        hidePrevButton
        hideNextButton
      />
    </PaginationWrapper>
  )
}

export default Pagination

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

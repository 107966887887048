 
import React, { useEffect, useState } from 'react'
import { head, pathOr } from 'ramda'
import Input from 'components/atoms/Input'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { getFoundUsersByRelationType } from 'features/bpDetails/ducks/selectors'
import { getUsersByEmailRoutine } from 'features/bpDetails/ducks/actions'
import { isNotEmpty, isNotNilOrEmpty } from 'utils/ramda'
import {
  validateAssignmentField,
  validateAssignmentValues,
  validateEmailValue
} from 'features/createBp/ducks/schema'
import PhoneField from 'components/atoms/PhoneInput'
import NextButton from 'features/createBp/components/atoms/NextButton'
import PersonDetailsBox from 'features/createBp/components/atoms/PersonDetailsBox'

const emptyValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  relationType: '',
  expiresAt: ''
}

const UnitPersonForm = ({ relationType, userToEdit, onChange, hasAssignedUser }) => {
  const [formVisible, setFormVisible] = useState(false)
  const [autoFilled, setAutoFilled] = useState(false)
  const [email, setEmail] = useState('')
  const [hasValidEmail, setHasValidEmail] = useState(false)
  const [valid, setValid] = useState(false)
  const [phoneValid, setPhoneValid] = useState(false)
  const dispatch = useDispatch()
  const foundRelations = useSelector(getFoundUsersByRelationType)
  const [values, setValues] = useState(
    userToEdit
      ? userToEdit
      : emptyValues
  )

  const [saved, setSaved] = useState(false)

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = () => {
    setAutoFilled(false)
    setFormVisible(false)
    setSaved(true)
    onChange(values)
  }

  const searchUser = e => {
    e.preventDefault()
    setFormVisible(true)
    dispatch(getUsersByEmailRoutine({ email, type: relationType }))
  }

  useEffect(() => {
    validateEmailValue({ email }, setHasValidEmail)
    validateAssignmentValues(values, setValid)
  }, [values])

  const handleChangeEmail = (name, value) => {
    handleValueChange(name, value)
    setEmail(value)
  }

  useEffect(() => {
    if (isNotNilOrEmpty(userToEdit)) {
      setValues(userToEdit)
      setEmail(userToEdit.email)
      setSaved(true)
      handleValueChange('relationType', relationType)
    } else {
      setValues(emptyValues)
      setSaved(false)
      handleValueChange('relationType', relationType)
    }
  }, [userToEdit])

  useEffect(() => {
    if (isNotNilOrEmpty(foundRelations[relationType])) {
      setValues(prev => ({
        ...prev,
        ...head(foundRelations[relationType]),
        email
      }))
      setAutoFilled(true)
      setValid(true)
    } else {
      setValues(prev => ({
        ...prev,
        email
      }))
      setAutoFilled(false)
    }
  }, [foundRelations, email])

  useEffect(() => {
    isNotEmpty(email) && setFormVisible(true)
  }, [])

  const clearValues = () => {
    setValues({
      ...emptyValues,
      relationType
    })
    setEmail('')
    setSaved(false)
  }

  return (
    <ContentWrapper>
      {
        saved
          ? (
            <PersonDetailsBox
              details={values}
              handleEdit={clearValues}
              hideEdit={hasAssignedUser}
            />
          )
          : (
            <FormWrapper onSubmit={e => e.preventDefault()} key={`assignment-form-${relationType}`}>
              <Input
                type='email'
                required
                label='Email'
                value={pathOr('', ['email'], values)}
                name='email'
                onChange={handleChangeEmail}
                validate={validateAssignmentField(values)}
              />
              <CheckButtonWrapper>
                <CheckEmailButton disabled={!hasValidEmail} type='button' onClick={searchUser}>
                  check email
                </CheckEmailButton>
              </CheckButtonWrapper>
              {
                formVisible && (
                  <>
                    <SectionName>personal details</SectionName>
                    <Input
                      required
                      label='First name'
                      value={pathOr('', ['firstName'], values)}
                      name='firstName'
                      onChange={handleValueChange}
                      validate={validateAssignmentField(values)}
                      disabled={autoFilled}
                    />
                    <Input
                      required
                      label='Last name'
                      value={pathOr('', ['lastName'], values)}
                      name='lastName'
                      onChange={handleValueChange}
                      validate={validateAssignmentField(values)}
                      disabled={autoFilled}
                    />
                    <PhoneField
                      name='phoneNumber'
                      label='Phone number'
                      value={pathOr('', ['phoneNumber'], values)}
                      onChange={handleValueChange}
                      validate={autoFilled ? null : setPhoneValid}
                      disabled={autoFilled}
                      noValidation={autoFilled}
                      optional
                    />
                  </>
                )
              }
              <ButtonWrapper withMargin={!saved}>
                <NextButton
                  onClick={handleSubmit}
                  disabled={!valid || (autoFilled ? false : !phoneValid)}
                >
                  Save
                </NextButton>
              </ButtonWrapper>
            </FormWrapper>
          )
      }
    </ContentWrapper>
  )
}

export default UnitPersonForm

const FormWrapper = styled.form`
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  margin-bottom: 25px;
`

const SectionName = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: .1px;
  margin: 15px 0;
`

const CheckEmailButton = styled.button`
  background-color: ${({ theme }) => theme.colors.haiti};
  color: ${({ theme }) => theme.colors.white};
  border: 1px solid transparent;
  transition: all .3s;
  border-radius: 4px;
  padding: 5px 15px;

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.haiti};
    border-color: ${({ theme }) => theme.colors.haiti};
  }

  ${({ disabled, theme }) => disabled && css`
    background-color: ${theme.colors.grey[500]};
    color: ${theme.colors.darkGrey};
    border-color: ${theme.colors.grey[900]};
    cursor: default;

    &:hover {
      background-color: ${theme.colors.grey[500]};
      color: ${theme.colors.grey[900]};
      border-color: transparent;
    }
  `}
`

const CheckButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const ContentWrapper = styled.div`
  max-width: 320px;
  margin: 0 auto;
`

const ButtonWrapper = styled.div`
  margin-top: ${({ withMargin }) => withMargin ? '20px' : '0'};
`

import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import PageHeader from 'components/atoms/PageHeader'
import BackButton from 'components/atoms/BackButton'
import { PRIVATE_PATHS } from 'utils/paths'
import TemplateDataItem from 'features/analytics/compontents/TemplateDataItem'
import { TEMPLATE_ITEMS } from 'utils/constants'
import { selectAddTemplateFiles, selectAddTemplateValues } from 'features/analytics/ducks/selectors'
import DataDropContainer from 'features/analytics/compontents/DataDropContainer'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import { getFileTypes } from 'ducks/files/selectors'
import { selectFileCategories } from 'ducks/dictionaries/selectors'
import CategoryPanel from 'features/analytics/compontents/CategoryPanel'
import FilesDropContainer from 'features/analytics/compontents/FilesDropContainer'
import PrimaryButton from 'components/atoms/PrimaryButton'
import { createAnalyticsTemplateRoutine } from 'features/analytics/ducks/actions'
import Modal from 'components/atoms/Modal'
import Input from 'components/atoms/Input'
import FullWidthButton from 'features/auth/components/FullWidthButton'
import { isEmpty, values } from 'ramda'
import SearchIcon from '@material-ui/icons/Search'

const AddTemplate = () => {
  const selectedValues = useSelector(selectAddTemplateValues)
  const selectedFilesValues = useSelector(selectAddTemplateFiles)
  const [searchQuery, setSearchQuery] = useState('')
  const dispatch = useDispatch()
  const [name, setName] = useState({
    main: 'New template',
    temp: 'New template'
  })
  const fileTypes = useSelector(getFileTypes)
  const fileCategories = useSelector(selectFileCategories)
  const [nameModalOpen, setNameModalOpen] = useState(false)

  const openNameModal = () => setNameModalOpen(true)
  const closeNameModal = () => {
    setNameModalOpen(false)
    setName(prev => ({ ...prev, temp: name.main }))
  }

  const templateItems = useMemo(() => {
    return TEMPLATE_ITEMS.filter(item => !selectedValues[item.value])
  }, [selectedValues])

  const filePanels = useMemo(() => {
    if (fileCategories && fileTypes) {
      const isSearching = !isEmpty(searchQuery)
      const selectedFilesIds = selectedFilesValues.map(e => e.id)
      const filteredTypes = fileTypes.filter(type => !selectedFilesIds.includes(type.id))
      const types = isSearching
        ? filteredTypes.filter(type => type.name.toLowerCase().includes(searchQuery.toLowerCase()))
        : filteredTypes

      const categories = fileCategories.map(category => {
        return {
          id: category.id,
          label: category.name,
          types: types.filter(type => type.fileCategoryId === category.id) || []
        }
      })

      return isSearching
        ? types.map(type => {
          // random key is a workaround for production issues
          return <TemplateDataItem key={Math.random() * 999} item={type} type='template-file' />
        })
        : categories.map(category => {
          return <CategoryPanel key={category.id} category={category} />
        })
    }
  }, [fileTypes, fileCategories, selectedFilesValues, searchQuery])

  const handleSubmit = () => {
    dispatch(createAnalyticsTemplateRoutine({
      name: name.main,
      fileTypes: selectedFilesValues.map(e => e.id),
      ...selectedValues
    }))
  }

  const shouldDisabledButton = () => {
    return values(selectedValues).every(val => val === false) && isEmpty(selectedFilesValues)
  }

  return (
    <PageWrapper>
      <BackButton to={PRIVATE_PATHS.analytics}>Back to Analytics</BackButton>
      <PageHeader>
        Add template
      </PageHeader>
      <PageContent>
        <Header>
          <Title>
            {name.main}
            <EditIcon onClick={openNameModal} />
          </Title>
        </Header>
        <DataDropContainer />
        <FilesDropContainer />
        <ButtonWrapper>
          <PrimaryButton
            onClick={handleSubmit}
            disabled={shouldDisabledButton()}
          >
            Add template
          </PrimaryButton>
        </ButtonWrapper>
      </PageContent>
      <SidePanel>
        <SidePanelTitle>
          templates
        </SidePanelTitle>
        <SidePanelDescription>
          Drag and drop to add an item to a custom template
        </SidePanelDescription>
        <SidePanelSectionTitle>
          <span>Data</span>
        </SidePanelSectionTitle>
        <SidePanelTemplatesList>
          {
            templateItems.map(item => {
              return <TemplateDataItem key={item.label} item={item} type='template-data' />
            })
          }
        </SidePanelTemplatesList>
        <SidePanelSectionTitle>
          <span>Files</span>
        </SidePanelSectionTitle>
        <Input
          startAdornment={<SearchIcon />}
          name='searchQuery'
          value={searchQuery}
          onChange={(_, value) => setSearchQuery(value)}
          nomarginbottom
        />
        <SidePanelTemplatesList>
          {filePanels}
        </SidePanelTemplatesList>
      </SidePanel>
      <Modal
        open={nameModalOpen}
        onClose={closeNameModal}
        title='Set template name'
      >
        <Input
          name='name'
          value={name.temp}
          onChange={(_, value) => setName(prev => ({ ...prev, temp: value }))}
        />
        <FullWidthButton
          disabled={isEmpty(name.temp)}
          onClick={() => {
            setName(prev => ({ ...prev, main: name.temp }))
            setNameModalOpen(false)
          }}
        >
          Save
        </FullWidthButton>
      </Modal>
    </PageWrapper>
  )
}

export default AddTemplate

const PageWrapper = styled.div`
  padding-bottom: 50px;
`

const PageContent = styled.div`
  margin-right: 280px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
  padding: 20px 40px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 20px;
`

const SidePanel = styled.div`
  width: 280px;
  position: fixed;
  top: ${({ theme }) => theme.dimensions.topBarHeight};
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  padding: 25px 20px;
  overflow-y: auto;
`

const SidePanelTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.haiti};
  margin-bottom: 5px;
`

const SidePanelDescription = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[800]};
`

const SidePanelSectionTitle = styled.div`
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};

  span {
    opacity: .6;
  }
`

const SidePanelTemplatesList = styled.div`
  padding: 10px 0;
`

const EditIcon = styled(EditOutlinedIcon)`
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  transition: all .3s;
  margin-left: 10px;

  &:hover {
    opacity: .8;
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

import React, { useEffect, useState } from 'react'
import { AddButton, MainContentPanel, SectionHeader, SectionHeaderWrapper } from 'features/bpDetails/BpDetails'
import styled from 'styled-components'
import { isNil, propOr } from 'ramda'
import Input from 'components/atoms/Input'
import PrimaryButton from 'components/atoms/PrimaryButton'
import { useDispatch, useSelector } from 'react-redux'
import { updateBpRoutine } from 'features/createBp/ducks/actions'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'

const ModelPanel = () => {
  const bp = useSelector(getSelectedBp)
  const currentUser = useSelector(getCurrentUser)
  const isFrs = currentUser.type === 'emergency_service'
  const [modelUrl, setModelUrl] = useState(propOr(null, '3dModelUrl', bp))
  const [isValid, setValid] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const dispatch = useDispatch()

  const bpUrl = propOr(null, '3dModelUrl', bp)

  const validateUrl = url => url && url.startsWith('<iframe') && url.endsWith('</iframe>')

  useEffect(() => {
    setValid(validateUrl(modelUrl))
  }, [modelUrl])

  const handleSubmit = () => {
    dispatch(updateBpRoutine({
      id: bp.id,
      '3dModelUrl': modelUrl
    }))
    setIsEdit(false)
  }

  return (
    <>
      <MainContentPanel>
        <SectionHeaderWrapper noMargin withBorder>
          <SectionHeader>3D model</SectionHeader>
          {bpUrl && !isFrs && <AddButton onClick={() => setIsEdit(true)}>edit</AddButton>}
        </SectionHeaderWrapper>
        <ModelWrapper id='model-wrapper'>
          {
            bpUrl && !isEdit
              ? <div dangerouslySetInnerHTML={{
                __html: bpUrl
                  .replace(/width=".*?"/, 'title="model" width="100%"')
                  .replace(/height=".*?"/, 'height="500px"')
              }} />
              : isFrs
              ? (
                <EmptyDescription>
                  3D plan not added
                </EmptyDescription>
                )
              : (
                <>
                  <EmptyDescription>
                    To connect a 3D model to your Building Passport, please paste the embed code into the box below.
                  </EmptyDescription>
                  <Input
                    onChange={(_, value) => setModelUrl(value)}
                    value={modelUrl}
                    name='model-url'
                    multiline
                    rows={6}
                    nomarginbottom
                  />
                  {
                    !isValid && !isNil(modelUrl) && <Error>Iframe tag is not valid</Error>
                  }
                  <ButtonWrapper>
                    <PrimaryButton disabled={!isValid} onClick={handleSubmit}>
                      Save
                    </PrimaryButton>
                  </ButtonWrapper>
                </>
              )
          }
        </ModelWrapper>
      </MainContentPanel>
    </>
  )
}

export default ModelPanel

const ModelWrapper = styled.div`
  width: 100%;
`

const EmptyDescription = styled.div`
  margin: 20px 0 0;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`

const Error = styled.div`
  color: ${({ theme }) => theme.colors.error};
  margin-top: 5px;
`

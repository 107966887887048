import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import styled from 'styled-components'
import { TextField } from '@material-ui/core'
import Checkbox from 'components/atoms/Checkbox'
import { Autocomplete } from '@material-ui/lab'
import { propOr } from 'ramda'
import FloorBadges from 'features/bpDetails/Components/files/FloorBadges'
import { isNotNilOrEmpty } from 'utils/ramda'

export const ShareUnitSelect = props => {
  const {
    // TODO: Refactored the .defaultProps here. There seems to be a number of props not in use. Check if those are used anywhere
    // validate= null,
    // name= '',
    // label= '',
    // value= '',
    // required= false,
    // disabled= false,
    // placeholder= '',
    // withDelete= false,
    // onDelete= () => {},
    // multiple = false,
    // disabledValues =  null,

    variant = 'outlined',
    options = [],
    noMargin = false,
    onChange = () => {},
    values,
    onSubmit = () => {}
  } = props

  const handleChange = (e, value) => {
    e.preventDefault()
    onChange(value)
  }

  const isOptionSelected = unitId => {
    const selectedUnitsIds = values.map(unit => unit.value)
    return selectedUnitsIds.includes(unitId)
  }

  const handleSelectAll = () => {
    onChange(options)
  }

  return (
    <Wrapper>
        <LabelWrapper>
          <Label>
            <LabelWrapper>
              Share file with
            </LabelWrapper>
          </Label>
          <ButtonsWrapper>
            <SelectAllButton onClick={handleSelectAll}>select all</SelectAllButton>
            <SaveButton onClick={onSubmit}>Confirm</SaveButton>
          </ButtonsWrapper>
        </LabelWrapper>
      <StyledFormControl noMargin={noMargin} variant={variant} required>
        <Autocomplete
          multiple
          onChange={handleChange}
          options={options}
          limitTags={0}
          getLimitTagsText={more => `${more} selected`}
          disableCloseOnSelect
          value={values}
          getOptionLabel={option => option.label}
          getOptionSelected={(option, value) => value.value === option.value}
          renderOption={props => {
            return (
              <MenuListItem>
                <Checkbox
                  style={{ marginRight: 8 }}
                  value={isOptionSelected(props.value)}
                />
                <OptionDetailsWrapper>
                  <div>
                    <UnitName>
                      {props.label}
                    </UnitName>
                    <UnitUprn>
                      UPRN: {propOr('Not yet allocated', 'uprn', props)}
                    </UnitUprn>
                  </div>
                  <FloorNumbersWrapper>
                    Floors:&nbsp; {isNotNilOrEmpty(props.floorNumbers) ? <FloorBadges floorNumbers={props.floorNumbers} /> : '---'}
                  </FloorNumbersWrapper>
                </OptionDetailsWrapper>
              </MenuListItem>
            )
          }}
          style={{ width: '500px' }}
          renderInput={(params) => (
            <TextField {...params} placeholder='Select units' />
          )}
        />
      </StyledFormControl>
    </Wrapper>
  )
}

export default ShareUnitSelect

const Wrapper = styled.div`
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin-bottom: ${({ noMargin }) => noMargin ? 0 : '22px'}!important;
  box-sizing: border-box;
  min-height: 46px;

  .MuiOutlinedInput-input {
    box-sizing: border-box;
    padding: 14px 15px;

    &:focus {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }

  .MuiAutocomplete-tag {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.grey[600]};
    background-color: ${({ theme }) => theme.colors.grey[400]}!important;
  }
`

const UnitName = styled.div`
  font-size: 14px;
  font-weight: bold;
`

const UnitUprn = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[800]};
`

const MenuListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 480px;
  height: 50px;
  padding: 0 10px;

  label {
    display: flex;
    align-items: center;
    width: 30px;
    height: 20px;
  }
`

const SelectAllButton = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  cursor: pointer;
`

const SaveButton = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  cursor: pointer;
`

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: .11px;
  min-height: 17px;
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`

const OptionDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const FloorNumbersWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
`

import React, { useMemo, useState } from 'react'
import Select from 'components/atoms/Select'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import Input from 'components/atoms/Input'
import NextButton from 'features/createBp/components/atoms/NextButton'
import Modal from 'components/atoms/Modal'
import { getNoteFloorsOptions } from 'utils/bpData'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import styled from 'styled-components'
import { addNoteRoutine } from 'features/createBp/ducks/actions'
import { dissoc } from 'ramda'
import { useParams } from 'react-router'

const defaultNoteValues = {
  floorNumber: 'general',
  message: ''
}

const AddNoteModal = ({ children }) => {
  const [open, setOpen] = useState(false)
  const { id } = useParams()
  const [values, setValues] = useState(defaultNoteValues)
  const dispatch = useDispatch()

  const handleModalOpen = () => setOpen(true)
  const handleModalClose = () => setOpen(false)

  const payload = useMemo(() => {
    if (values.floorNumber === 'general') {
      return {
        ...dissoc('floorNumber', values),
        onClose: handleModalClose,
        id
      }
    } else if (values.floorNumber === 'notice') {
      return {
        ...dissoc('floorNumber', values),
        isNoticeForAllUnits: true,
        onClose: handleModalClose,
        id
      }
    } else if (values.floorNumber === 'ground') {
      return {
        ...values,
        floorNumber: 0,
        onClose: handleModalClose,
        id
      }
    } else {
      return {
        ...values,
        onClose: handleModalClose,
        id
      }
    }
  }, [values])

  const bp = useSelector(getSelectedBp)
  const noteFloorOptions = useMemo(getNoteFloorsOptions(bp), [bp])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleAddNote = () => {
    dispatch(addNoteRoutine(payload))
  }

  return (
    <>
      <Trigger onClick={handleModalOpen}>
        {children}
      </Trigger>
      <Modal
        title='Add note'
        open={open}
        onClose={handleModalClose}
      >
        <FormWrapper>
          <Select
            name='floorNumber'
            options={noteFloorOptions}
            value={values.floorNumber}
            label='Select'
            onChange={handleValueChange}
            noMargin={values.floorNumber === 'notice'}
          />
          {
            values.floorNumber === 'notice' && (
              <InputDescriptionWrapper>
                <InputDescription>
                  Notice will be sent to all users with access to any unit within this building.
                </InputDescription>
              </InputDescriptionWrapper>
            )
          }
          <MessageInputWrapper>
            <Input
              multiline
              nomarginbottom
              rows={4}
              name='message'
              onChange={handleValueChange}
            />
          </MessageInputWrapper>
          <NextButton onClick={handleAddNote}>
            Add note
          </NextButton>
        </FormWrapper>
      </Modal>
    </>

  )
}

export default AddNoteModal

const FormWrapper = styled.div`
  max-width: 320px;
  margin: 0 auto;
`

const InputDescriptionWrapper = styled.div`
  margin: 5px 0 15px;
`

const MessageInputWrapper = styled.div`
  padding: 5px 12px 12px;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  margin-bottom: 20px;
`

const Trigger = styled.div`
  width: fit-content;
  cursor: pointer;
`

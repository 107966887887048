import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

const CountButton = ({
  onChange = () => {},
  disabled = false,
  label = '',
  name = '',
  min = 0,
  value = 0
}) => {
  const [buttonValue, setValue] = useState(value || min)

  useEffect(() => {
    buttonValue !== value && onChange(name, buttonValue)
  }, [buttonValue])

  const increment = () => disabled ? () => {} : setValue(prev => prev + 1)
  const decrement = () => disabled ? () => {} : setValue(prev => prev - 1)

  const handleInputChange = e => setValue(
    Number(e.target.value) > min
      ? Number(e.target.value)
      : min
  )

  return (
    <>
      <Label>{label}</Label>
      <Wrapper>
        <ActionButton
          onClick={buttonValue > min ? decrement : () => {}}
          disabled={buttonValue === min || disabled}
        >
          <RemoveIcon />
        </ActionButton>
        <ValueWrapper disabled={disabled} type='number' onChange={handleInputChange} value={buttonValue} />
        <ActionButton
          disabled={disabled}
          onClick={increment}
        >
          <AddIcon />
        </ActionButton>
      </Wrapper>
    </>
  )
}

export default CountButton

const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 1px;

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  input[type=number] {
      -moz-appearance:textfield;
  }
`

const ValueWrapper = styled.input`
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  outline: none;
  border: none;
  color: ${({ theme, disabled }) => disabled ? theme.colors.grey[700] : 'default'};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const ActionButton = styled.div`
  box-sizing: border-box;
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  cursor: pointer;
  font-size: 40px;
  transition: all .3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundColorDark};
  }

  ${({ disabled }) => disabled && css`
    color: ${({ theme }) => theme.colors.grey[700]};

    &:hover {
      background-color: ${({ theme }) => theme.colors.backgroundColor};
      cursor: default;
    }
  `}
`

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: .11px;
`

import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Input from 'components/atoms/Input'
import styled from 'styled-components'
import { head, isEmpty } from 'ramda'
import PrimaryButton from 'components/atoms/PrimaryButton'
import { shareAnalyticsTemplateRoutine } from 'features/analytics/ducks/actions'
import { validateShareTemplateField, validateShareTemplateValues } from 'features/analytics/ducks/schema'
import { getBpConnectedUsers } from 'features/bpDetails/ducks/selectors'
import { clearConnectedUsersRoutine, getBpConnectedUsersByEmailRoutine } from 'features/bpDetails/ducks/actions'

const ShareTemplateModal = ({ template, closeModal }) => {
  const connectedUsers = useSelector(getBpConnectedUsers)
  const dispatch = useDispatch()
  const [searched, setSearched] = useState(false)
  const [email, setEmail] = useState('')
  const [valid, setValid] = useState(false)

  const foundUser = useMemo(() => {
    return head(connectedUsers) || []
  }, [connectedUsers])

  const handleSearch = () => {
    dispatch(getBpConnectedUsersByEmailRoutine(email))
    setSearched(true)
  }

  const handleShareTemplate = () => {
    dispatch(shareAnalyticsTemplateRoutine({
      userId: foundUser.id,
      templateId: template.id
    }))
    dispatch(clearConnectedUsersRoutine())
    setSearched(false)
    closeModal()
  }

  useEffect(() => {
    validateShareTemplateValues({ email }, setValid)
  }, [email])

  return (
    <FormContent>
      <InputsRow>
        <Input
          type='email'
          name='email'
          label='Email'
          value={email}
          onChange={(_, value) => setEmail(value)}
          validate={validateShareTemplateField({ email })}
        />
        <PrimaryButton
          disabled={!valid}
          onClick={handleSearch}
        >
          Search
        </PrimaryButton>
      </InputsRow>
        {
          !isEmpty(foundUser) && (
            <FoundUserDetails>
              <div>
                {foundUser.firstName} {foundUser.lastName}
              </div>
              <ShareButton onClick={handleShareTemplate}>share</ShareButton>
            </FoundUserDetails>
          )
        }
        {
          isEmpty(foundUser) && searched && (
            <div>There is no user connected with this email address</div>
          )
        }
    </FormContent>
  )
}

export default ShareTemplateModal

const InputsRow = styled.div`
  display: flex;
  align-items: center;

  & > div:first-of-type {
    margin-right: 15px;
  }

  button {
    height: 45px;
  }
`

const FormContent = styled.div`

`

const FoundUserDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  border-radius: 4px;
`

const ShareButton = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  font-weight: bold;
  width: fit-content;
  cursor: pointer;
`
